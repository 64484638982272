export const data = {
  data() {
    return {
      data: [
        {
          label: "Je souhaite m'inscrire",
          routeName: "",
          active: false,
        },
        {
          label: "Facturation et forfaits",
          routeName: "",
          active: false,
        },
        {
          label: "Problème de connexion",
          routeName: "",
          active: false,
        },
        {
          label: "Information",
          routeName: "",
          active: false,
        },
        {
          label: "Tutoriel",
          routeName: "",
          active: false,
        },
        {
          label: "L'outil Exval",
          routeName: "",
          active: true,
        },
        {
          label: "Assistance",
          routeName: "",
          active: false,
        },
      ],
    };
  },
};
