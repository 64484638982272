<template>
  <div class="w-11/12">
    <p class="title">Nous contacter</p>
    <p class="text-md mb-7 text-primary font-light">
      Questions, signalement de bogues, commentaires... Nous sommes toujours à
      votre écoute.
    </p>
    <p class="text-primary text-sm font-medium">Séléctionner un thème:</p>

    <div class="mt-2">
      <SousMenu
        :data="data"
        v-model:title="selectedSousMenu"
        @selected-label="tag"
      />

      <div class="">
        <router-view :name="selectedSousMenu.routeName" />
      </div>
    </div>
    <p class="text-primary font-medium mt-7 text-sm">
      Puis remplissez les champs ci-dessous:
    </p>
    <form ref="form" @submit.prevent="sendLeadAdmin">
      <div class="box-name">
        <input class="hidden" v-model="select" name="select" />
        <input
          type="text"
          v-model="firstname"
          name="firstname"
          minlength="3"
          maxlength="20"
          placeholder="Prénom"
          class="bg-input"
          required
        />
        <input
          type="text"
          v-model="lastname"
          name="lastname"
          minlength="2"
          maxlength="20"
          placeholder="Nom"
          class="bg-input"
          required
        />
      </div>
      <input
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        type="number"
        v-model="phone"
        name="phone"
        placeholder="+33"
        class="bg-input phone-number"
        minlength="10"
        maxlength="10"
      />
      <input
        type="email"
        v-model="email"
        name="email"
        placeholder="Adresse email"
        class="bg-input"
        minlength="5"
        maxlength="35"
        required
      />
      <textarea
        v-model="message"
        name="message"
        placeholder="Précisez votre demande..."
        class="bg-input text-area"
        maxlength="400"
        minlength="15"
      ></textarea>
      <router-link to="/confidentialite">
        <p class="Rgles-de-confidentialit">Règles de confidentialité</p>
      </router-link>
      <button class="bg-button" type="submit">Obtenir de l'aide</button>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { data } from "./data";
import SousMenu from "../b2b6/SousMenu";
import emailjs from "emailjs-com";
import axios from "axios";

export default defineComponent({
  name: "Contact",
  components: { SousMenu },
  mixins: [data],
  data() {
    return {
      selectedSousMenu: {},
      firstname: "",
      lastname: "",
      phone: null,
      email: "",
      message: "",
      select: "",
    };
  },
  methods: {
    sendLeadAdmin() {
      axios({
          method: "POST",
          url: "https://exval.app/api/leads",
          data:  {
            firstname: this.firstname,
            lastname: this.lastname,
            phone: this.phone,
            email: this.email,
            message: this.message,
            type: this.select,
          }
        })
          .then(function () {
            
          })
         // Reset form field
         this.firstname = "";
            this.lastname = "";
            this.phone = null;
            this.email = "";
            this.message = "";
    },
    sendEmail() {
      try {
        emailjs.sendForm(
          "service_9cmh77f",
          "template_yluhh68",
          this.$refs.form,
          "user_VUHVYKOEHPzm68oBLBcVV",
          {
            firstname: this.firstname,
            lastname: this.lastname,
            phone: this.phone,
            email: this.email,
            message: this.message,
            select: this.select,
          }
        );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.firstname = "";
      this.lastname = "";
      this.phone = null;
      this.email = "";
      this.message = "";
    },
    tag(value) {
      this.select = value;
    },
  },
});
</script>

<style scoped>
input::placeholder {
  color: #999999;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}
.color {
  background-color: #ffe7ed;
}
.button-transparent {
  @apply my-1 mr-2 py-2 px-4 text-pink-700 rounded-3xl color;
}
.title {
  width: 177px;
  height: 36px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: left;
  color: #363660;
}
.bg-input {
  max-width: 550px;
  width: 100%;
  height: 50px;
  margin: 8px 0 0px;
  padding: 17px;
  border-radius: 4px;
  border: solid 1px #c4c4c4;
  background-color: #ffffff;
}
.bg-button {
  width: 200px;
  height: 52px;
  margin: 16px 2px 16px 0;
  border-radius: 4px;
  font-size: 14px;
  background-color: #e94a73;
  color: white;
}
.label {
  width: 117px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.Rgles-de-confidentialit {
  width: 174px;
  margin: 15px 0 0 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  text-decoration: underline;
}

.box-name {
  display: flex;
  justify-content: space-between;
  max-width: 550px;
}
.box-name > .bg-input {
  width: 49%;
}

.phone-number::-webkit-outer-spin-button,
.phone-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.phone-number::placeholder {
  color: #363660;
}
textarea {
  resize: none;
}
.text-area {
  height: 111px;
}
.text-area::placeholder {
  font-size: 13px;
  color: #999999;
}
</style>
