<template>
  <div class="h-screen flex flex-col">
    <slot name="header"></slot>
    <div class="grid grid-cols-1 md:grid-cols-10 h-full">
      <div
        style="background-color: #f6f7fb; padding-top: 60px"
        class="col-span-7 flex justify-center"
      >
        <div class="w-3/5">
          <slot name="contact"></slot>
        </div>
      </div>

      <div class="col-span-3 bg-primary">
        <img
          class="md:fixed bottom-0 right-0 md:w-2/5"
          src="../assets/element-contact-bg.svg"
          alt="message-square"
        />
        <div class="info-ctn flex">
          <div class="text1 infos-bulle">
            Puis-je vous aider, que <br />
            cherchez-vous ?
          </div>
          <div class="elipse">
            <img src="../assets/message-square.svg" alt="message-square" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "B2b5Layout",
});
</script>

<style scoped>
.infos-bulle {
  width: 200px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;
  border-radius: 10px;
  background-color: #ffffff;
  /* temporarily  */
  display: none;
}
.text1 {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
.info-ctn {
  position: fixed;
  right: 48px;
  bottom: 40px;
}
.elipse {
  width: 48px;
  height: 48px;
  margin: 5px 0 4px 10px;
  padding: 12px;
  background-color: #4cbfde;
  border-radius: 100%;

  /* temporarily  */
  display: none;
}
</style>