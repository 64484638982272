<template>
    <div class="Tag_container cursor-pointer" style="margin-right: 3px ; margin-bottom: 3px;" title="">
        <span :class="{'Tag-Normal-6': active, 'Tag-Normal-1': !active }" class="Tag">{{label}}</span>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: 'BadgeTag',
  components: {},
  props: {
      label: {
          type: String,
          default: 'Votre label'
      },
      active: {
          type: Boolean,
          default: false
      }
  },
})
</script>

<style scoped>
.Tag-Normal-1 {
  color: #e94a73;
  height: 36px;
  border-radius: 24px;
  background-color: #ffe7ed;
}

.Tag-Normal-6 {
  color: #ffffff;
  border-radius: 24px;
  background-color: #e94a73;
}

.Tag {
  width: auto;
  height: 36px;
  padding: 8px 16px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
}
.Tag_container {
    position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
}

</style>