<template>
  <B2b5Layout>
    <template v-slot:header>
      <Header />
    </template>
    <template v-slot:contact>
      <Contact />
    </template>
  </B2b5Layout>
</template>

<script>
import { defineComponent } from "vue";
import Contact from "../components/b2b5/Contact.vue";
import Header from "../components/b2b5/Header.vue";
import B2b5Layout from "../layout/B2b5Layout";

export default defineComponent({
  name: "B2b5",
  components: {
    B2b5Layout,
    Contact,
    Header,
  },
});
</script>

<style>
</style>