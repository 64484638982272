<template>
  <span class="col-start-2 col-end-4 sous_menu gap-1">
    <BadgeTag
      v-for="badge in dataChanged"
      @click="() => onClicked(badge.label)"
      :key="badge.label"
      :label="badge.label"
      :active="badge.active"
    />
  </span>
</template>

<script scoped >
import { defineComponent } from "vue";
import BadgeTag from "../public/BadgeTag.vue";

export default defineComponent({
  name: "SousMenu",
  components: {
    BadgeTag,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      dataChanged: [],
    };
  },
  mounted() {
    this.dataChanged = [...this.data];
    this.selected = this.data.find((item) => item.active == true) || null;
    this.$emit("selected-label", this.selected.label);
  },
  methods: {
    onClicked(label) {
      if (this.dataChanged.length > 0)
        this.dataChanged = this.dataChanged.map((item) => {
          if (item.label == label) item.active = !item.active;
          else item.active = false;
          return item;
        });
      this.selected =
        this.dataChanged.find((item) => item.active == true) || null;
      this.$emit("selected-label", this.selected.label);
    },
  },
});
</script>

<style>
.sous_menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  user-select: none;
}
</style>