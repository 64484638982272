<template >
  <div class="md:mx-auto">
    <div class="grid grid-cols-12 h-20 items-center">
      <router-link
        class="col-start-1 col-span-2 flex justify-start"
        to="/"
        style="cursor: pointer; margin-left: 30px"
      >
        <img class="arrow-left pb-1" src="../../assets/arrow-left.png" />
        <p class="text-base text-primary underline p-2">Retour accueil</p>
      </router-link>
      <div class="col-start-3 p-5">
        <img
          src="../../assets/exvalLogo.png"
          alt="exval logo"
          style="margin-left: -40px"
        />
      </div>
      <!-- <div class="col-start-7 col-span-6 flex">
        <p
          class="text-base text-primary underline p-2 text-center-help"
          style="font-size: 16px"
        >
          Centre d'assistance
        </p>
        <div class="bg-input ml-2">
          <img class="search" src="../../assets/search.png" />
          <input
            class="w-full input-search"
            type="text"
            placeholder="Rechercher dans le centre d'assisstance"
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Header",
});
</script>


<style scoped>
.input-search {
  @apply border-2 border-gray-300 rounded p-2 pl-12;
}
.input-search::placeholder {
  margin: 0 0 1px 10px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
}
.arrow-left {
  @apply w-5 h-5 mt-3;
}
.search {
  @apply arrow-left ml-5 absolute;
}
.bg-input {
  width: 500px;
}
.text-center-help {
  padding-top: 11px;
}
</style>